import React, {
  useState,
  useMemo,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useContext,
} from "react"
import moment from "moment"
import _get from "lodash/get"
import _findIndex from "lodash/findIndex"
import _map from "lodash/map"
import _size from "lodash/size"
import _filter from "lodash/filter"
import _last from "lodash/last"
import _isNil from "lodash/isNil"
import _set from "lodash/set"

import {
  MAX_ALLOWED_BOOKINGS,
  NUMBERS,
  SPACE_BOOKING_TYPES,
  STATUS,
} from "../constants/common"
import {
  areAllBookingsValid,
  calculateBookingAnounts,
  getDeskBookingCharges,
  getDeskBookingPayload,
  getInitialDayBooking,
  getMinSeats,
  getNewBookingPlaceholder,
  isInstantlyBookableWeWork,
  isInstantlyBookableAwfis,
  isInstantlyBookableInnov8,
} from "../utils/common"
import DateItem from "./DateItem"
import { getSeatsAvailable } from "../api/common"
import TimeItem from "./TimeItem"
import SeatSelector from "./SeatSelector"
import BookingPrice from "./BookingPrice"

const DeskBooking = forwardRef(
  ({ spaceDetails, timeStamp, disableEdit, user, priceDiscount }, ref) => {
    const [dayBookings, setDayBookings] = useState([])

    useEffect(() => {
      async function getInitialBooking() {
        const initialBooking = getInitialDayBooking(timeStamp, spaceDetails)
        updateSeatsAvailable(_get(initialBooking, "id"), initialBooking)
        setDayBookings([initialBooking])
      }
      getInitialBooking()
    }, [])

    const price = useMemo(() => {
      return calculateBookingAnounts(
        user,
        spaceDetails,
        dayBookings,
        "Shared Workspace",
        priceDiscount
      )
    }, [spaceDetails, dayBookings, user, priceDiscount])

    useImperativeHandle(
      ref,
      () => ({
        getBookingPayload() {
          const validBookings = areAllBookingsValid(
            spaceDetails,
            dayBookings,
            "Day"
          )

          console.log({ validBookings })
          if (validBookings.status === STATUS.FAILED) {
            alert(validBookings.msg)
            return false
          }
          return getDeskBookingPayload(spaceDetails, user, dayBookings)
        },
        getPriceToCollect() {
          return price
        },
      }),
      [spaceDetails, user, dayBookings]
    )

    const updateSeatsAvailable = async (id, bookingDetails) => {
      const seatsAvailable = await getSeatsAvailable({
        spaceId: _get(spaceDetails, "spaceId"),
        dates: [_get(bookingDetails, "bookingDate")],
        numberOfSeats: 1,
        duration: 1,
      })

      setDayBookings(bookings => {
        console.log({ bookings, id })
        const newDayBookings = [...bookings]
        const currentBooking = _findIndex(
          newDayBookings,
          booking => _get(booking, "id") === id
        )
        if (currentBooking !== -1) {
          newDayBookings[currentBooking] = { ...bookingDetails, seatsAvailable }
        }
        return newDayBookings
      })
    }

    const addBooking = async () => {
      const lastBooking = _last(dayBookings)
      const newPlaceHolder = getNewBookingPlaceholder(
        _get(lastBooking, "bookingDate"),
        _get(lastBooking, "startTime"),
        spaceDetails
      )
      // const seatsAvailable = await getSeatsAvailable({
      //   spaceId: _get(spaceDetails, 'spaceId'),
      //   dates: [_get(newPlaceHolder, 'bookingDate')],
      //   numberOfSeats: 1,
      //   duration: 1,
      // });
      // _set(newPlaceHolder, 'seatsAvailable', seatsAvailable);
      setDayBookings([...dayBookings, newPlaceHolder])
      updateSeatsAvailable(_get(newPlaceHolder, "id"), newPlaceHolder)
    }

    const removeBooking = index => () => {
      console.log({ index })
      setDayBookings(
        _filter(dayBookings, (_, bookingIndex) => bookingIndex !== index)
      )
    }

    const updateBookings = (index, key, value) => {
      const currentDayBookings = [...dayBookings]
      setDayBookings(_set(currentDayBookings, `${index}.${key}`, value))
    }

    const updateSeatsNumber = index => seats => {
      if (
        seats >= getMinSeats(spaceDetails) &&
        seats <= _get(dayBookings, `${index}.seatsAvailable`, 0)
      ) {
        // setNoOfSeats(seats)
        updateBookings(index, "noOfSeats", seats)
      }
    }

    const onSelectDateTime = index => async (mode, time) => {
      if (mode === "date") {
        if (time > moment().startOf("day").valueOf()) {
          updateBookings(index, "seatsAvailable", null)
          updateBookings(index, "bookingDate", time)
          updateBookings(index, "startTime", time)
          updateSeatsAvailable(
            _get(dayBookings[index], "id"),
            dayBookings[index]
          )
        } else {
          // toast.show("Please select a valid date.")
          alert("Please select a valid date.")
        }
      } else {
        updateBookings(index, "startTime", time)
      }
    }

    return (
      <>
        {/* <div
          className="mb-2"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 8,
            backgroundColor: "#F8F8F9",
            borderRadius: 4,
          }}
        >
          <p className="mb-0">Credits Needed</p>
          <p className="mb-0">{`₹ ${price}`}</p>
        </div> */}

        <BookingPrice price={price} />
        {isInstantlyBookableAwfis(spaceDetails) ||
        isInstantlyBookableInnov8(spaceDetails) ||
        isInstantlyBookableWeWork(spaceDetails) ? (
          <p className="bold" style={{ fontSize: 10 }}>
            * Cancellations must be made by midnight the day before your
            reservation.
          </p>
        ) : null}

        {_map(
          dayBookings,
          ({ bookingDate, startTime, noOfSeats, seatsAvailable }, index) => (
            <React.Fragment key={index}>
              {_size(dayBookings) > 1 ? (
                <div className="d-flex flex-row align-items-center justify-content-between mt-3">
                  <p className="mb-0" style={{ color: "#878787" }}>
                    {NUMBERS[index]}&nbsp; Booking
                  </p>
                  <p
                    className="mb-0"
                    style={{
                      textDecorationLine: "underline",
                      color: "#878787",
                    }}
                    onClick={removeBooking(index)}
                  >
                    Cancel
                  </p>
                </div>
              ) : null}

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto auto",
                  rowGap: 16,
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <p className="bold" style={{ fontSize: 14 }}>
                    Date
                  </p>
                  <DateItem
                    date={new Date(Number(bookingDate))}
                    onSelect={onSelectDateTime(index)}
                  />
                </div>
                <div>
                  <p className="bold" style={{ fontSize: 14 }}>
                    Time
                  </p>

                  <TimeItem
                    date={new Date(Number(startTime))}
                    onSelect={onSelectDateTime(index)}
                  />
                </div>
                <div>
                  <p className="bold" style={{ fontSize: 14 }}>
                    Day Booking
                  </p>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      borderRadius: 5,
                      width: 38,
                      height: 38,
                      backgroundColor: "#2798B5",
                    }}
                  >
                    <i
                      className="fa fa-check"
                      aria-hidden="true"
                      style={{ color: "white" }}
                    ></i>
                  </div>
                </div>
                <div>
                  <p className="bold" style={{ fontSize: 14 }}>
                    Seats
                  </p>
                  <SeatSelector
                    seats={noOfSeats}
                    setSeats={updateSeatsNumber(index)}
                    disableEdit={disableEdit}
                  />
                  <p className="mb-0 mt-1" style={{ fontSize: 12 }}>
                    {!_isNil(seatsAvailable)
                      ? `${seatsAvailable}/${_get(
                          spaceDetails,
                          "seatsAvailable"
                        )} seats available`
                      : "Loading..."}
                  </p>
                </div>
              </div>
            </React.Fragment>
          )
        )}
        {/* {_size(dayBookings) < MAX_ALLOWED_BOOKINGS &&
        _size(dayBookings) > 0 &&
        !disableEdit ? (
          <p
            className="bold mt-3 fs-6"
            style={{
              textDecorationLine: "underline",
              color: "#878787",
              cursor: "pointer",
            }}
            role="button"
            tabIndex={0}
            onClick={addBooking}
          >
            {_size(dayBookings) < 2 ? "Book multiple dates" : "Add more"}
          </p>
        ) : null} */}
        <div className="mb-4"></div>
      </>
    )
  }
)

export default DeskBooking
