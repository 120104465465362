import React, { useState } from "react"
import _get from "lodash/get"
import _map from "lodash/map"

const BookingPrice = ({ price }) => {
  const [showDetails, setShowDetails] = useState(false)

  const toggleShowDetails = () => setShowDetails(!showDetails)

  return (
    <div
      className="mb-2"
      style={{
        padding: 8,
        backgroundColor: "#F8F8F9",
        borderRadius: 4,
      }}
      onClick={toggleShowDetails}
    >
      {!showDetails ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="mb-0 bold">Total Payable Amount</p>
          <p className="mb-0 bold">
            {`₹ ${_get(price, "totalPrice")}`}{" "}
            <i class="fa fa-chevron-down" aria-hidden="true"></i>
          </p>
        </div>
      ) : (
        _map(_get(price, "breakup"), ({ label, value }) => {
          let className = "mb-0"
          if (label === "Total Payable Amount") className += " bold"
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className={className}>{label}</p>
              <p className={className}>
                {`₹ ${value}`}{" "}
                {label === "Space Usage Fee" ? (
                  <i class="fa fa-chevron-up" aria-hidden="true"></i>
                ) : (
                  <span style={{ padding: "10px" }}></span>
                )}
              </p>
            </div>
          )
        })
      )}
    </div>
  )
}

export default BookingPrice
